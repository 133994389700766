<form novalidate (ngSubmit)="getRecordsIfNeeds($event)" autocomplete="new-password">
    <div *ngIf="this.options.isFilterEnabled" class="input-group mb-4">
        <input
            autoFocus
            [(ngModel)]="filterText"
            name="filterText"
            class="form-control"
            placeholder="{{ 'SearchWithThreeDot' | localize }}"
            type="text"
        />
        <div class="input-group-append">
            <button class="btn btn btn-primary" type="submit">
                <i class="fa-duotone fa-magnifying-glass" [attr.aria-label]="l('Search')"></i>
            </button>
        </div>
    </div>

    <!--<Primeng-TurboTable-Start>-->
    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
        <p-table
            #dataTable
            (onLazyLoad)="getRecordsIfNeeds($event)"
            [value]="primengTableHelper.records"
            rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
            [paginator]="false"
            [lazy]="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 15%">
                        {{ 'Select' | localize }}
                    </th>
                    <th>
                        {{ 'Name' | localize }}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record="$implicit">
                <tr>
                    <td>
                        <button
                            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                            (click)="selectItem(record)"
                            title="{{ 'Select' | localize }}"
                        >
                            <i class="la la-chevron-circle-right" [attr.aria-label]="l('Select')"></i>
                        </button>
                    </td>
                    <td>
                        <span class="p-column-title">{{ 'Name' | localize }}</span>
                        {{ record.name }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
            {{ 'NoData' | localize }}
        </div>
        <div class="primeng-paging-container">
            <p-paginator
                [rows]="primengTableHelper.defaultRecordsCountPerPage"
                #paginator
                (onPageChange)="getRecordsIfNeeds($event)"
                [totalRecords]="primengTableHelper.totalRecordsCount"
                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                [showCurrentPageReport]="true"
                [currentPageReportTemplate]="'TotalRecordsCount' | localize : primengTableHelper.totalRecordsCount"
            ></p-paginator>
        </div>
    </div>
    <!--<Primeng-TurboTable-End>-->
</form>
